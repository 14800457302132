<template>
    <Checkbox v-model="checked" value="1" :binary="true" @change="onChanged"></Checkbox>
</template>
<script>
import Checkbox from 'primevue/checkbox';
import { PwgsApi } from '../../../services/PwgsApi';

export default {
    components: {
        Checkbox
    },
    props: [
        'props',
        'datos',
    ],
    data() {
        return {
            checked: false
        }
    },
    methods: {
       async onChanged() {
            this.$emit('cambioAusente', this.checked);
            /*  modulos/pwgsapi/index.php/usuarios/:id/estado */
            const api = new PwgsApi;
            const subidadatos = {estado_usuario:'activo'};
            if (this.checked == true) {
                subidadatos.estado_usuario = 'ausente';
            }
            else {
                subidadatos.estado_usuario = 'activo';
            }
            try {
               await api.put('usuarios/' + this.datos.idusuario + '/estado', subidadatos);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
            }
            catch (error) {
               await this.$toast.add({ severity: 'error', summary: 'Error', detail: 'El trabajo no se puede modificar ya que está incluido en otro presupuesto generado', life: 5000 });
            }
        }
    },
    mounted() {
        const datos = this.datos;
        this.checked = this.props.checked(datos);
    }
}
</script>